export default {
  title: 'Mon compte',
  submit_button: 'Mettre à jour les informations',
  password_button: 'Modifier le mot de passe',
  fields: [
    {
      type: 'text',
      name: 'first_name',
      label: 'Prénom',
      placeholder: 'Votre prénom',
      component: 'el-input',
      required: true,
      pattern: /^[a-zA-Z0-9._-\s]+$/,
      errorMessage: 'Votre prénom n\'est pas valide',
    },
    {
      type: 'text',
      name: 'last_name',
      label: 'Nom de famille',
      placeholder: 'Votre nom',
      component: 'el-input',
      required: true,
      pattern: /^[a-zA-Z0-9._-\s]+$/,
      errorMessage: 'Votre nom n\'est pas valide',
    },
    {
      type: 'text',
      name: 'email',
      label: 'Adresse email',
      placeholder: 'Votre adresse email',
      component: 'el-input',
      required: true,
      pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      errorMessage: 'Votre adresse email n\'est pas valide',
    },
    {
      type: 'text',
      name: 'company',
      label: 'Entreprise',
      placeholder: 'Votre entreprise',
      required: false,
      component: 'el-input',
      pattern: /^[a-zA-Z0-9._-\s]+$/,
      errorMessage: "Votre nom d'entreprise n'est pas valide",
    }
  ],
  edit_field_box: {
    title: 'Editer votre',
    confirmButtonText: 'Editer',
    cancelButtonText: 'Annuler',
  },
  email_info_box: {
    title: 'Email',
    text: 'Si vous souhaitez modifier votre adresse email, veuillez contacter un administrateur.',
    confirmButtonText: 'J\'ai compris',
  },

};
